import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

// Plugins
import moment from 'moment';
import CsvParse from 'papaparse';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { Tooltip } from 'react-tippy';
import { differenceBy } from 'lodash';
import { toast, Slide } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createFlyerEmail, getPeopleFieldOptions, getPeopleList } from '../../../../actions';
import { getOrganization } from '../../../../../Organizations/actions';

// Components
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';
import subjectsFilterOptions from '@/utils/subjectsFilterOptions';
import { pickerDate } from '@/utils/displayFormats';

// Images
import imageIconPdf from '@/assets/images/icon-pdf-file.png';
import imageDragCvs from '@/assets/images/drag-csv-file.svg';
import imageDesignBasicFull from '@/assets/images/flyer-design-basic-full.png';
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Styles
import './style.css';
import 'react-toastify/dist/ReactToastify.min.css';

const Email = memo(function Email({ type, job, flyer, isPrivateJob, onEmailToggle, onFlyerSave }) {
  const dispatch = useDispatch();

  const {
    people: { fields, requesting: peopleRequesting },
    flyer: { flyer_offer_type: flyerType }
  } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.login);
  const { organization } = useSelector((state) => state.organizations);

  const [showNext, setShowNext] = useState(false);

  const [selectSubjectsFilter, setSelectSubjectsFilter] = useState(null);
  const [fieldSelected, setFieldSelected] = useState({ value: '', label: '' });
  const [fieldValues, setFieldValues] = useState([{ value: '', label: '' }]);
  const [fieldOptions, setFieldOptions] = useState([{ value: '', label: '' }]);
  const [groupOptions, setGroupOptions] = useState([]);

  const [peopleList, setPeopleList] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [removedRecipients, setRemovedRecipients] = useState([]);

  const [inputEmail, setInputEmail] = useState('');
  const [emailColumnsAvailable, setEmailColumnsAvailable] = useState([]);
  const [emailColumnsSelected, setEmailColumnsSelected] = useState([]);

  const [subjectPurchasedFilter, setSubjectPurchasedFilter] = useState('');
  const [subjectAccessedFilter, setSubjectAccessedFilter] = useState('');
  const [subjectFilterRemoved, setSubjectFilterRemoved] = useState([]);
  const [subjectEmailFilter, setSubjectEmailFilter] = useState('');

  const [subjectsPurchasedIds, setSubjectsPurchasedIds] = useState([]);
  const [subjectsNotPurchasedIds, setSubjectsNotPurchasedIds] = useState([]);
  const [subjectsAccessedIds, setSubjectsAccessedIds] = useState([]);
  const [subjectsNotAccessedIds, setSubjectsNotAccessedIds] = useState([]);
  const [subjectsWithEmailIds, setSubjectsWithEmailIds] = useState([]);

  const [email, setEmail] = useState({ emails: [], subject: '', message: '' });
  const [includeCustomerEmails, setIncludeCustomerEmails] = useState(false);

  const filterOptionsShow = fieldValues?.length > 0 && fieldValues[0].label !== '' ? true : false;

  const fetchPeopleListWithFilter = (filterSelect) => {
    dispatch(
      getPeopleList(
        { id: job.id, per_page: 10000, order: 'last_name', dir: 'asc', search_accuracy: 'exact', subject_filter: filterSelect, with_customer_info: true },
        ({ data }) => {
          const { job_status: jobStatus } = job;

          setPeopleList(data);
          setFieldSelected({ value: 'everyone', label: 'Everyone' });

          const peopleListAccessedIds = data.filter((person) => person?.accessed === true).map((person) => person.id);
          setSubjectsAccessedIds(peopleListAccessedIds);

          const peopleListNotAccessedIds = data.filter((person) => person?.accessed === false).map((person) => person.id);
          setSubjectsNotAccessedIds(peopleListNotAccessedIds);

          const peopleListPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === true)
            .map((person) => person.id);
          setSubjectsPurchasedIds(peopleListPurchasedIds);

          const peopleListNotPurchasedIds = data
            .filter((person) => person?.[jobStatus === 'presale' ? 'has_advancepay' : 'has_orders'] === false)
            .map((person) => person.id);
          setSubjectsNotPurchasedIds(peopleListNotPurchasedIds);
        }
      )
    );
  };

  const resetRecipientList = () => {
    const reset = [...recipients, ...subjectFilterRemoved].sort((a, b) => {
      const nameA = a.last_name.toLowerCase();
      const nameB = b.last_name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return reset;
  };

  const resetFilterByOptions = () => {
    if (subjectPurchasedFilter) {
      handleFilterByPurchasedOptions({ target: { name: subjectPurchasedFilter, checked: false } });
    }

    if (subjectAccessedFilter) {
      handleFilterByAccessedOptions({ target: { name: subjectAccessedFilter, checked: false } });
    }
  };

  // UI Handlers
  const handleEmailAdd = ({ key, target }) => {
    if (key === 'Enter') {
      const value = target.value;

      if (value.includes('@')) {
        setEmail({ ...email, emails: [...email.emails.filter((item) => item !== value), target.value] });
        setInputEmail('');
      }
    }
  };

  const handleEmailRemove = (remove) => {
    setEmail({ ...email, emails: email.emails.filter((item) => item !== remove) });
  };

  const handleEmailSelect = (event) => {
    const name = event.target.name;

    let newEmailColumnsSelected = [...emailColumnsSelected];
    let newEmailIncludeCustomer = !!includeCustomerEmails;

    if (name === 'allEmail') {
      if (newEmailColumnsSelected.length === emailColumnsAvailable.length && includeCustomerEmails) {
        newEmailColumnsSelected = [];
        // Also DEselect the Include Customer Emails as well
        newEmailIncludeCustomer = false;
      } else {
        newEmailColumnsSelected = emailColumnsAvailable;
        // Also select the Include Customer Emails as well
        newEmailIncludeCustomer = true;
      }
    } else {
      if (emailColumnsSelected.includes(name)) {
        newEmailColumnsSelected = newEmailColumnsSelected.filter((column) => column !== name);
      } else {
        newEmailColumnsSelected = newEmailColumnsSelected.concat(name);
      }
    }

    resetFilterByOptions();
    setIncludeCustomerEmails(newEmailIncludeCustomer);
    setRecipientsFromEmailColumns(newEmailColumnsSelected, newEmailIncludeCustomer);
    setEmailColumnsSelected(newEmailColumnsSelected);
  };

  const handleIncludeCustomerEmailsChange = ({ target }) => {
    const targetChecked = !!target.checked;

    let newEmailColumnsSelected = [...emailColumnsSelected];
    let newEmailIncludeCustomer = targetChecked;

    resetFilterByOptions();
    setIncludeCustomerEmails(targetChecked);
    setRecipientsFromEmailColumns(newEmailColumnsSelected, newEmailIncludeCustomer);
  };

  const handleSubjectsFilterChange = (select) => {
    setSelectSubjectsFilter(select);

    // Reset other filters
    setFieldSelected({ value: '', label: '' });
    setFieldValues([{ value: '', label: '' }]);
    setRemovedRecipients([]);
    resetFilterByOptions();

    // Fetch new subjects based on filter
    fetchPeopleListWithFilter(select?.value);
  };

  const handleFieldSelectedChange = (select) => {
    setFieldSelected(select ? select : { value: '', label: '' });
    setFieldValues([{ value: '', label: '' }]);
    setRemovedRecipients([]);
    resetFilterByOptions();
  };

  const handleFieldValuesChange = (select) => {
    setFieldValues(select);
    resetFilterByOptions();
  };

  const handleFilterByPurchasedOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectPurchasedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let recipientsWithoutPurchases = currentRecipients.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
      let recipientsWithPurchases = currentRecipients.filter((subject) => subjectsPurchasedIds.includes(subject.id));

      if (subjectAccessedFilter) {
        if (subjectAccessedFilter === 'subjectsNotAccessed') {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
        } else {
          recipientsWithoutPurchases = recipientsWithoutPurchases.filter((subject) => subjectsAccessedIds.includes(subject.id));
          recipientsWithPurchases = recipientsWithPurchases.filter((subject) => subjectsAccessedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectAccessedFilter) {
        const withoutAccessed = currentRecipients.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
        const withAccessed = currentRecipients.filter((subject) => subjectsAccessedIds.includes(subject.id));

        const newRecipients = subjectAccessedFilter === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }

    // Reset no email filter
    setSubjectEmailFilter('');
  };

  const handleFilterByAccessedOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectAccessedFilter(checked ? name : '');

    const currentRecipients = resetRecipientList();

    if (checked) {
      let withoutAccessed = currentRecipients.filter((subject) => subjectsNotAccessedIds.includes(subject.id));
      let withAccessed = currentRecipients.filter((subject) => subjectsAccessedIds.includes(subject.id));

      if (subjectPurchasedFilter) {
        if (subjectPurchasedFilter === 'subjectsNoPurchase') {
          withoutAccessed = withoutAccessed.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
        } else {
          withoutAccessed = withoutAccessed.filter((subject) => subjectsPurchasedIds.includes(subject.id));
          withAccessed = withAccessed.filter((subject) => subjectsPurchasedIds.includes(subject.id));
        }
      }

      const newRecipients = name === 'subjectsNotAccessed' ? withoutAccessed : withAccessed;
      const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

      setRecipients(newRecipients);
      setSubjectFilterRemoved(newSubjectFilterRemoved);
    } else {
      if (subjectPurchasedFilter) {
        const recipientsWithoutPurchases = currentRecipients.filter((subject) => subjectsNotPurchasedIds.includes(subject.id));
        const recipientsWithPurchases = currentRecipients.filter((subject) => subjectsPurchasedIds.includes(subject.id));

        const newRecipients = subjectPurchasedFilter === 'subjectsNoPurchase' ? recipientsWithoutPurchases : recipientsWithPurchases;
        const newSubjectFilterRemoved = differenceBy(currentRecipients, newRecipients, 'id');

        setRecipients(newRecipients);
        setSubjectFilterRemoved(newSubjectFilterRemoved);
      } else {
        setRecipients(currentRecipients);
        setSubjectFilterRemoved([]);
      }
    }

    // Reset no email filter
    setSubjectEmailFilter('');
  };

  const handleFilterByEmailOptions = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    setSubjectEmailFilter(checked ? name : '');

    if (checked) {
      const recipientsWithoutEmail = recipients.filter((subject) => !subject.email && !subject.email_2 && !subject.email_3);
      const recipientsWithEmail = recipients.filter((subject) => subject.email || subject.email_2 || subject.email_3);

      setRecipients(recipientsWithoutEmail);
      setSubjectsWithEmailIds(recipientsWithEmail.map((subject) => subject.id));
      setSubjectFilterRemoved((prevRemovedSubjects) => [...prevRemovedSubjects, ...recipientsWithEmail]);
    } else {
      const recipientsRemoved = subjectFilterRemoved.filter((subject) => subjectsWithEmailIds.includes(subject.id));

      setSubjectsWithEmailIds([]);
      setRecipients((prevState) => [...prevState, ...recipientsRemoved].sort((a, b) => a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())));
      setSubjectFilterRemoved((prevState) => prevState.filter((subject) => !subjectsWithEmailIds.includes(subject.id)));
    }
  };

  const handleDropAccepted = (acceptedFiles) => {
    const file = acceptedFiles[0];

    CsvParse.parse(file, {
      quotes: false,
      header: true,
      worker: true,
      skipEmptyLines: 'greedy',
      complete: (results) => {
        const { meta, data } = results;
        const emailFieldName = meta.fields.find((field) => field.toLowerCase().includes('email') || field.toLowerCase().includes('e-mail'));
        const toastErrorOptions = {
          autoClose: 3000,
          transition: Slide,
          closeOnClick: false,
          pauseOnHover: false,
          position: 'top-right',
          type: 'error'
        };

        if (emailFieldName) {
          const csvEmails = data?.map((item) => item[emailFieldName])?.filter((item) => item?.includes('@'));
          const recipientEmails = [...new Set(csvEmails)];

          setEmail({ ...email, emails: [...email.emails, ...recipientEmails.filter((item) => email.emails.includes(item) === false)] });
        } else {
          toast('Unable to find email field in CSV file', toastErrorOptions);
        }
      }
    });
  };

  const handleRemoveRecipient = (removedRecipient) => {
    const currentGroup = fieldSelected.value;
    const newRecipients = recipients.filter((person) => person.id !== removedRecipient.id);

    // RemovedRecipients list is needed to know which people should not be added back,
    // in the useEffect as fieldValues change when another field is added or removed.
    setRecipients(newRecipients);
    setRemovedRecipients([...removedRecipients, removedRecipient]);

    // Remove group select if everyone is selected or if recipient has unique group value
    if (currentGroup === 'everyone') {
      const currentFieldValues = JSON.stringify(fieldValues);
      const newFieldValues = fieldValues.filter((field) => {
        if (currentGroup !== 'everyone') {
          return removedRecipient[currentGroup] !== field.value;
        } else {
          return removedRecipient['id'] !== field.value;
        }
      });

      if (currentFieldValues !== JSON.stringify(newFieldValues)) {
        setFieldValues(newFieldValues);
      }
    }
  };

  const handleEmailSend = () => {
    const { updated_at: jobUpdatedAt } = job;
    const { offers: flyerOffers, updated_at: flyerUpdatedAt } = flyer;

    // Check if job or offers has changed since last time flyer updated
    // if true save flyer first to update it before download
    let shouldSaveFlyer = false;

    if (moment(flyerUpdatedAt).isBefore(jobUpdatedAt) || flyerOffers.some((offer) => moment(flyerUpdatedAt).isBefore(offer.updated_at))) {
      shouldSaveFlyer = true;
    }

    if (shouldSaveFlyer) {
      onFlyerSave(() =>
        dispatch(
          createFlyerEmail(
            {
              id: flyer.id,
              ...email,
              email_columns: emailColumnsSelected,
              subjects_sort: flyer.sorting,
              subject_fields: { id: recipients.map((recipient) => recipient.id) },
              render_in_emails: type === 'people',
              include_customer_emails: includeCustomerEmails
            },
            () => onEmailToggle()
          )
        )
      );
    } else {
      dispatch(
        createFlyerEmail(
          {
            id: flyer.id,
            ...email,
            email_columns: emailColumnsSelected,
            subjects_sort: flyer.sorting,
            subject_fields: { id: recipients.map((recipient) => recipient.id) },
            render_in_emails: type === 'people',
            include_customer_emails: includeCustomerEmails
          },
          () => onEmailToggle()
        )
      );
    }
  };

  const handleEmailNext = () => {
    if (isPrivateJob && type === 'people') {
      const emails = [];
      recipients.forEach((recipient) =>
        emailColumnsSelected.forEach((column) => {
          if (recipient[column] !== null && recipient[column].length > 0) emails.push(recipient[column]);
        })
      );
      setEmail({
        emails,
        subject: '',
        message: ''
      });
    }

    setShowNext(!showNext);
  };

  const setRecipientsFromEmailColumns = (emailColumns, emailIncludeCustomer) => {
    let recipientsWithEmailColumn = [];

    recipientsWithEmailColumn = peopleList.filter((recipient) => {
      // Filter out people intentionally removed from right side
      if (removedRecipients.some((removed) => removed.id === recipient.id)) {
        return false;
      }

      // Filter out people not selected in field values
      if (!fieldValues.some((field) => field.value === recipient[fieldSelected.value === 'everyone' ? 'id' : fieldSelected.value])) {
        return false;
      }

      // Remove recipients that have emails but not from a column selected
      // NOR from has_associated_customers
      if (
        !emailColumns.some((column) => recipient[column] && recipient[column].length > 0) &&
        (!emailIncludeCustomer || (emailIncludeCustomer && recipient.has_associated_customers === false))
      ) {
        return false;
      }

      return true;
    });

    setRecipients(recipientsWithEmailColumn);
  };

  const recipientRow = ({ recipient }) => {
    return (
      <figure key={recipient.id} className="flex middle nowrap job-marketing-flyers__email-recipient animate">
        <div className="job-marketing-flyers__email-recipient-image-box">
          <LazyLoadImage
            className="job-marketing-flyers__email-recipient-image"
            src={recipient.session_photo_url ? imageScaling({ url: recipient.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
            alt={recipient.session_photo_filename}
            height={40}
            draggable="false"
          />
        </div>
        <figcaption className="flex between middle">
          <div className="flex column job-marketing-flyers__email-recipient-details">
            <p className="text--bold text--capitalize text--truncate text--nomargin">{recipient.last_name + ', ' + recipient.first_name} </p>
            <p className="text--small text--nomargin">{`photos: ${recipient.photos_count}`} </p>
            <p className="text--truncate text--small text--nomargin">
              {fieldSelected.value !== 'everyone'
                ? `${fieldSelected.label}: ${
                    fieldSelected.value === 'session_start' ? pickerDate(recipient[fieldSelected.value] ?? '') : recipient[fieldSelected.value]
                  }`
                : ''}
            </p>
          </div>
          <button className="button button--clean button-noborder" type="button" onClick={() => handleRemoveRecipient(recipient)}>
            <i className="icon-trash"></i>
          </button>
        </figcaption>
      </figure>
    );
  };

  const recipientRowEmail = ({ recipient }) => {
    return (
      <figure key={recipient.id} className="flex middle nowrap job-marketing-flyers__email-recipient animate">
        <div className="job-marketing-flyers__email-recipient-image-box">
          <LazyLoadImage
            className="job-marketing-flyers__email-recipient-image"
            src={recipient.session_photo_url ? imageScaling({ url: recipient.session_photo_url, size: 'small' }) : imagePeopleThumbnailPlaceholder}
            alt={recipient.session_photo_filename}
            height={40}
            draggable="false"
          />
        </div>
        <figcaption className="flex between middle">
          <div className="job-marketing-flyers__email-recipient-details">
            <div className="flex column job-marketing-flyers__email-recipient-details--scroll">
              <p className="text--bold text--capitalize text--truncate text--nomargin">{recipient.last_name + ', ' + recipient.first_name} </p>
              <p className="text--small text--nomargin">{`photos: ${recipient.photos_count}`} </p>
              <p className="text--truncate text--small text--nomargin">
                {recipient.email && emailColumnsSelected.includes('email') && `${recipient.email}, `}
                {recipient['email_2'] && emailColumnsSelected.includes('email_2') && `${recipient.email_2}, `}
                {recipient['email_3'] && emailColumnsSelected.includes('email_3') && `${recipient.email_3}`}
              </p>
            </div>
          </div>
          <button className="button button--clean button-noborder" type="button" onClick={() => handleRemoveRecipient(recipient)}>
            <i className="icon-trash"></i>
          </button>
        </figcaption>
      </figure>
    );
  };

  const getSendButtonSection = ({ buttonDisabled }) => {
    const showNotPublishedWarning = job.job_status === 'draft' ? true : false;

    const sendButton = (
      <button
        className="button button--center button--medium"
        name="send"
        type="button"
        onClick={handleEmailSend}
        data-loading={peopleRequesting}
        disabled={buttonDisabled}
      >
        Send
      </button>
    );

    if (showNotPublishedWarning) {
      return (
        <aside className="panel panel--dark panel--round panel--nomargin flex between middle gap-10">
          <p className="flex middle gap-10 text--nomargin">
            <i className="icon-warning" /> This gallery has not been published yet. Are you sure you want to email this flyer?
          </p>
          {sendButton}
        </aside>
      );
    } else {
      return sendButton;
    }
  };

  const filterOptions = ({ showEmailFilter } = { showEmailFilter: false }) => {
    return (
      <div className="mt-20 animate">
        <p>Optional Filters:</p>
        <h6 className="mt-10 text--uppercase">Filter by purchase</h6>
        <fieldset>
          <input
            type="checkbox"
            className="hidden"
            name="subjectsPurchase"
            id="subjectsPurchase"
            checked={subjectPurchasedFilter === 'subjectsPurchase'}
            onChange={handleFilterByPurchasedOptions}
          />
          <label htmlFor="subjectsPurchase" className="label--checkbox">
            Only Subjects with Purchases
          </label>
        </fieldset>
        <fieldset>
          <input
            type="checkbox"
            className="hidden"
            name="subjectsNoPurchase"
            id="subjectsNoPurchase"
            checked={subjectPurchasedFilter === 'subjectsNoPurchase'}
            onChange={handleFilterByPurchasedOptions}
          />
          <label htmlFor="subjectsNoPurchase" className="label--checkbox">
            Only Subjects without Purchases
          </label>
        </fieldset>
        <h6 className="mt-10 text--uppercase">Filter by accessed gallery</h6>
        <fieldset>
          <input
            type="checkbox"
            className="hidden"
            name="subjectsAccessed"
            id="subjectsAccessed"
            checked={subjectAccessedFilter === 'subjectsAccessed'}
            onChange={handleFilterByAccessedOptions}
          />
          <label htmlFor="subjectsAccessed" className="label--checkbox">
            Only Subjects That Accessed Gallery
          </label>
        </fieldset>
        <fieldset>
          <input
            type="checkbox"
            className="hidden"
            name="subjectsNotAccessed"
            id="subjectsNotAccessed"
            checked={subjectAccessedFilter === 'subjectsNotAccessed'}
            onChange={handleFilterByAccessedOptions}
          />
          <label htmlFor="subjectsNotAccessed" className="label--checkbox">
            Only Subjects That Have NOT Accessed Gallery
          </label>
        </fieldset>
        {showEmailFilter && (
          <>
            <h6 className="mt-10 text--uppercase">Filter by email</h6>
            <fieldset>
              <input
                id="subjectsNoEmail"
                className="hidden"
                name="subjectsNoEmail"
                type="checkbox"
                checked={subjectEmailFilter === 'subjectsNoEmail'}
                onChange={handleFilterByEmailOptions}
              />
              <label htmlFor="subjectsNoEmail" className="label--checkbox">
                Only Subjects without an Email
              </label>
            </fieldset>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (fieldSelected.value === 'everyone') {
      const newFieldOptions = peopleList
        .sort((a, b) => a.last_name.localeCompare(b.last_name))
        .map((person) => ({ label: `${person.last_name}, ${person.first_name}`, value: person.id }));

      setFieldOptions(newFieldOptions);
      setFieldValues(newFieldOptions);
    } else {
      const fieldLabel = fieldSelected.label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
      const fieldValue = fieldSelected.value;

      setFieldOptions([
        ...(fieldValue.match(/first_name|last_name/) ? [] : [{ label: `${fieldLabel} Empty`, value: '' }]),
        ...(fields[fieldSelected.value] ? fields[fieldSelected.value].sort().map((field) => ({ label: field, value: field })) : [])
      ]);
    }
  }, [fieldSelected]);

  useEffect(() => {
    if (fieldSelected.value && fieldValues.length > 0) {
      // Update field value if No/Yes to true and false before use it
      const fieldValuesMapped = fieldValues.map((values) => ({ ...values, value: { Yes: true, No: false }[values.value] ?? values.value }));
      const isFilteringForEmptyData = fieldValues.some((field) => field.value === '' && field.label.includes('Empty'));

      let newRecipients = [];
      let newRemovedRecipients = [];

      if (fieldSelected.value === 'everyone') {
        newRecipients = peopleList.filter((person) => fieldValuesMapped.find((field) => field.value === person.id) && !subjectFilterRemoved.includes(person));
      } else if (fieldSelected.value === 'session_start') {
        // Since subjects use datetime and field options only date
        newRecipients = peopleList.filter((person) => fieldValuesMapped.find((field) => field.value === pickerDate(person[fieldSelected.value] ?? '')));
      } else {
        newRecipients = peopleList.filter((person) => {
          if (isFilteringForEmptyData && !person[fieldSelected.value]) {
            return true;
          }

          return fieldValues.find((field) => field.value === person[fieldSelected.value]);
        });
      }

      // Remove people from the RemovedRecipients list because their field value has been removed.
      if (removedRecipients.length > 0) {
        newRemovedRecipients = removedRecipients.filter((person) => {
          if (fieldSelected.value === 'everyone') {
            return true;
          } else {
            return fieldValuesMapped.some((fieldValue) => fieldValue.value === person[fieldSelected.value]);
          }
        });

        // Remove people that were on the removedRecipients list.
        newRecipients = newRecipients.filter((person) => !newRemovedRecipients.some((removedPerson) => removedPerson.id === person.id));
      }

      // Remove people without any email addresses
      if (type === 'people') {
        newRecipients = newRecipients.filter((person) => ['email', 'email_2', 'email_3'].some((column) => person[column]));
      }

      if (newRecipients.length > 0) {
        const emailColumnsAvailable = [];

        newRecipients.forEach((person) => {
          const columns = Object.keys(person).filter((key) => key.includes('email'));

          columns.forEach((column) => {
            if (person[column] && !emailColumnsAvailable.includes(column)) {
              emailColumnsAvailable.push(column);
            }
          });
        });

        emailColumnsAvailable.sort();
        setEmailColumnsAvailable(emailColumnsAvailable);
        setEmailColumnsSelected(emailColumnsAvailable);
      }

      setRemovedRecipients(newRemovedRecipients);
      setRecipients(newRecipients);
    } else {
      setRecipients([]);
      setRemovedRecipients([]);
    }
  }, [fieldValues]);

  useEffect(() => {
    if (subjectPurchasedFilter) {
      handleFilterByPurchasedOptions({
        target: {
          name: subjectPurchasedFilter,
          checked: true
        }
      });
    }

    if (subjectAccessedFilter) {
      handleFilterByAccessedOptions({
        target: {
          name: subjectAccessedFilter,
          checked: true
        }
      });
    }
  }, [subjectPurchasedFilter, subjectAccessedFilter]);

  useEffect(() => {
    // Fetch organizations
    if (job.client_id) {
      dispatch(getOrganization({ organizationId: job.client_id }));
    }

    // Set initial email subject and body message
    const subject = `${job?.meta?.studio_name} has shared a flyer with you`;

    let message;

    if (isPrivateJob) {
      if (flyerType === 'pre_picture') {
        message = `Hi there,\n\nThe key to a successful picture day is making sure that every parent knows exactly how to find their child's photos.\n\nI've attached a unique flyer for each child that you can print and distribute.\n\nIf you have any questions, please reply to this email and I'll get right back to you.\n\nThank you,\n\n${
          user.first_name
        } ${user.last_name}\n\n${job.meta && job.meta.studio_name}`;
      } else if (flyerType === 'advance_pay') {
        message = `Hi there,\n\nThe key to a successful picture day is making sure that every parent knows exactly how to find their child's photos. Parents can get a jump on picture day by purchasing an AdvancePay credit for ${
          job?.name
        }.\n\nI've attached a unique flyer for each child that you can print and distribute.\n\nIf you have any questions, please reply to this email and I'll get right back to you.\n\nThank you,\n\n${
          user.first_name
        } ${user.last_name}\n\n${job?.meta && job?.meta.studio_name}`;
      } else {
        message = `Hi there,\n\nThe key to a successful picture day is making sure that every parent knows exactly how to find their child's photos. I'm going to need your help getting the word out.\n\nI've attached a unique flyer for each child that you can print and distribute.\n\nIf you have any questions, please reply to this email and I'll get right back to you.\n\nThank you,\n\n${
          user.first_name
        } ${user.last_name}\n\n${job?.meta && job?.meta?.studio_name}`;
      }
    } else {
      if (flyerType === 'pre_picture') {
        message = `Hi there,\n\nI’m excited about our upcoming picture day! The key to a successful, smooth-running picture day is communication and making sure that every parent knows exactly how to find their child's photos.\n\nI'm going to need your help getting the word out. This web page has all the essential details. Please share it with all of your parents so they can access their photos in the gallery: ${
          flyer.public_url
        }\n\nIn addition to the web page, you’ll also find a “View or Download Flyer” button below. Once downloaded, you can use the flyer as an email attachment or print and hand it out.\n\nIf you have any questions, please reply to this email, and I'll get right back to you.\n\nThank you,\n${
          user.first_name
        } ${user.last_name}\n\n${job.meta && job.meta.studio_name}\n${job.meta && job.meta.studio_website_url}`;
      } else if (flyerType === 'advance_pay') {
        message = `Hi there,\n\nI’m excited about our upcoming picture day! The key to a successful, smooth-running picture day is communication and making sure that every parent knows exactly how to find their child's photos. Parents can get a jump on picture day by purchasing an AdvancePay credit for ${
          job.name
        }.\n\nI'm going to need your help getting the word out. This web page has all the essential details. Please share it with all of your parents so they can access their photos in the gallery: ${
          flyer.public_url
        }\n\nIn addition to the web page, you’ll also find a “View or Download Flyer” button below. Once downloaded, you can use the flyer as an email attachment or print and hand it out.\n\nIf you have any questions, please reply to this email, and I'll get right back to you.\n\nThank you,\n${
          user.first_name
        } ${user.last_name}\n\n${job.meta && job.meta.studio_name}\n${job.meta && job.meta.studio_website_url}`;
      } else if (flyerType === 'post_picture') {
        message = `Hi there,\n\nWe’ve published the gallery, it’s being viewed, and order sales are trickling in!\n\nPlease help us get the word out by sharing the link below with all of your parents so they know how to access and order their photos: ${
          flyer.public_url
        }\n\n In addition to the web page, you’ll also find a “View or Download Flyer” button below. Once downloaded, you can use the flyer as an email attachment or print and hand it out.\n\nIf you have any questions, please reply to this email, and I'll get right back to you.\n\nThank you,\n${
          user.first_name
        } ${user.last_name}\n\n${job.meta && job.meta.studio_name}\n${job.meta && job.meta.studio_website_url}`;
      } else {
        // Gallery or Published type
        message = `Hi there,\n\nPhotos are ready! It’s time to spread the news and let everyone know how to view and purchase their photos from picture day.\n\nPlease help us by sharing the link below with all of your parents so they know how to access their photos: ${
          flyer.public_url
        }\n\n In addition to the web page, you’ll also find a “View or Download Flyer” button below. Once downloaded, you can use the flyer as an email attachment or print and hand it out.\n\nIf you have any questions, please reply to this email, and I'll get right back to you.\n\nThank you,\n${
          user.first_name
        } ${user.last_name}\n\n${job.meta && job.meta.studio_name}\n${job.meta && job.meta.studio_website_url}`;
      }
    }

    setEmail({ ...email, message, subject });

    if (isPrivateJob) {
      // Set initial subject Filter Option
      const initialSubjectFilterOption = subjectsFilterOptions[0];
      setSelectSubjectsFilter(initialSubjectFilterOption);

      // Fetch people data with initial filter
      fetchPeopleListWithFilter(initialSubjectFilterOption?.value);

      // Get people field options
      dispatch(
        getPeopleFieldOptions({ id: job.id, with_email: type === 'people' }, () => {
          const allEmailColumns = [];
          const groupOptions = Object.keys(fields)
            .filter((field) => {
              if (isPrivateJob && type === 'people') {
                if (field.includes('email')) {
                  allEmailColumns.push(field);
                }

                return !field.includes('email');
              } else {
                return true;
              }
            })
            .map((key) => ({ value: key, label: key.replace('session_start', 'check-in_date').replace('_', ' ') }));
          const options = [{ value: 'everyone', label: groupOptions.length === 0 ? 'No subjects with email addresses' : 'Everyone' }];

          setEmailColumnsAvailable(allEmailColumns);
          setEmailColumnsSelected(allEmailColumns);
          setGroupOptions(options.concat(groupOptions));
        })
      );
    }
  }, []);

  return (
    <div className="job-marketing-flyers__email">
      {isPrivateJob ? (
        <>
          {type === 'organization' && (
            <aside className="modal animate">
              <div className="modal__box modal__box--secondary modal__box--large">
                <button className="button button--action modal__close" name="close" type="button" onClick={onEmailToggle}>
                  <i className="icon-close"></i>
                </button>

                <main className="flex modal__content">
                  <section className="flex-5 flex-12-sm modal__content-section modal__content-section--dark">
                    <header className="modal__header">
                      <h2>Select Subjects</h2>
                      <p>Create flyers for the following people:</p>
                    </header>
                    <Select
                      className="select select--capitalize mb-10"
                      classNamePrefix="select"
                      isLoading={peopleRequesting}
                      value={selectSubjectsFilter}
                      options={subjectsFilterOptions}
                      onChange={handleSubjectsFilterChange}
                    />
                    <Select
                      className="select select--capitalize mb-10"
                      classNamePrefix="select"
                      placeholder="Select a group"
                      isClearable={true}
                      isLoading={peopleRequesting}
                      value={fieldSelected.value ? fieldSelected : ''}
                      options={groupOptions}
                      onChange={handleFieldSelectedChange}
                    />
                    <Select
                      className="select"
                      classNamePrefix="select"
                      isMulti={true}
                      isLoading={peopleRequesting}
                      value={fieldValues.length > 0 && (fieldValues[0].value || fieldValues[0].label) ? fieldValues : null}
                      isClearable={true}
                      options={fieldOptions}
                      onChange={handleFieldValuesChange}
                    />
                    {filterOptionsShow && filterOptions({ showEmailFilter: true })}
                  </section>
                  <section className="flex-7 flex-12-sm modal__content-section">
                    <header className="modal__header">
                      <h2>Subjects Included</h2>
                      {!peopleRequesting && (
                        <p>The pdf will includes flyers of the following {`${recipients.length === 1 ? 'subject' : 'subjects'} (${recipients.length}):`} </p>
                      )}
                    </header>
                    <div className="job-marketing-flyers__email-recipients">
                      {fieldSelected?.value && peopleList.length === 0 ? (
                        <p className="text--bold job-marketing-flyers__email-error animate">
                          There are no subjects matching the criteria you specified. Please update your selection.
                        </p>
                      ) : peopleRequesting ? (
                        <TableLoader rows={10} rowHeight={20} />
                      ) : (
                        <>{recipients.map((recipient) => recipientRow({ recipient }))}</>
                      )}
                    </div>
                    <footer className="modal__footer modal__footer--fixed flex end gap-10">
                      <button className="button button--outline" type="button" name="cancel" onClick={onEmailToggle}>
                        Cancel
                      </button>
                      <button
                        className="button"
                        name="next"
                        type="button"
                        onClick={handleEmailNext}
                        data-loading={peopleRequesting}
                        disabled={peopleRequesting || !recipients.length}
                      >
                        Next
                      </button>
                    </footer>
                  </section>
                </main>

                {/* Show Email Screen send to people */}
                {showNext && (
                  <aside className="modal__box--overlay animate">
                    <header className="modal__header modal__header--flex">
                      <button className="button button--clean button--noborder button--small" name="back" type="button" onClick={handleEmailNext}>
                        <i className="icon-back"></i>
                      </button>
                      <h2 className="text--nomargin">Compose Email</h2>
                    </header>
                    <section>
                      <CreatableSelect
                        isMulti
                        isClearable
                        name="emails"
                        autoFocus={true}
                        className="select mb-10"
                        classNamePrefix="select"
                        placeholder={'Enter Email Addresses'}
                        value={email.emails.map((email) => ({ label: email, value: email }))}
                        onChange={(select) =>
                          setEmail({
                            ...email,
                            emails: select ? select.filter((email) => email.value && email.value.includes('@')).map((email) => email.value) : []
                          })
                        }
                        options={organization?.contacts?.map((contact) => ({ value: contact.email, label: contact.email }))}
                      />
                      <input
                        className="input--block mb-10"
                        type="text"
                        name="subject"
                        value={email.subject}
                        placeholder="Enter Email Subject"
                        onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                        maxLength="50"
                        required
                      />
                      <textarea
                        className="textarea--block mb-10"
                        name="message"
                        value={email.message}
                        onChange={({ target }) => setEmail({ ...email, message: target.value })}
                        rows="14"
                        maxLength="1400"
                      />
                      <figure className="flex middle gap-10">
                        <img src={imageIconPdf} alt={flyer.name} width="22" />
                        <figcaption>
                          <small>{flyer.name.toLowerCase()}.pdf</small>
                        </figcaption>
                      </figure>
                    </section>
                    <footer className="modal__footer modal__footer--fixed">
                      {getSendButtonSection({ buttonDisabled: peopleRequesting || !(email.emails && email.emails.length && email.subject) })}
                    </footer>
                  </aside>
                )}
              </div>
            </aside>
          )}
          {type === 'people' && (
            <aside className="modal animate">
              <div className="modal__box modal__box--secondary modal__box--large">
                <button className="button button--action modal__close" name="close" type="button" onClick={onEmailToggle}>
                  <i className="icon-close"></i>
                </button>

                <main className="flex modal__content">
                  <section className="flex-5 flex-12-sm modal__content-section modal__content-section--dark">
                    <h2>Select Subjects</h2>
                    <p>Create Flyers for the following subjects:</p>
                    <Select
                      className="select select--capitalize mb-10"
                      classNamePrefix="select"
                      isLoading={peopleRequesting}
                      value={selectSubjectsFilter}
                      options={subjectsFilterOptions}
                      onChange={handleSubjectsFilterChange}
                    />
                    <Select
                      className="select select--capitalize mb-10"
                      classNamePrefix="select"
                      placeholder="Select a group"
                      isClearable={true}
                      isLoading={peopleRequesting}
                      value={fieldSelected.value ? fieldSelected : ''}
                      options={groupOptions}
                      onChange={handleFieldSelectedChange}
                    />
                    <Select
                      className="select"
                      classNamePrefix="select"
                      isMulti={true}
                      isLoading={peopleRequesting}
                      value={fieldValues.length > 0 && (fieldValues[0].value || fieldValues[0].label) ? fieldValues : null}
                      isClearable={true}
                      options={fieldOptions}
                      onChange={handleFieldValuesChange}
                    />
                    {filterOptionsShow && (
                      <div className=" mt-20 animate">
                        <p>Select which email(s) to use: </p>
                        {emailColumnsAvailable.length > 0 && (
                          <fieldset>
                            <input
                              type="checkbox"
                              className="hidden"
                              name="allEmail"
                              id="allEmail"
                              checked={emailColumnsSelected.length === emailColumnsAvailable.length && includeCustomerEmails}
                              onChange={handleEmailSelect}
                            />
                            <label htmlFor="allEmail" className="label--checkbox">
                              All
                            </label>
                          </fieldset>
                        )}

                        {emailColumnsAvailable.map((column, index) => (
                          <fieldset key={index}>
                            <input
                              type="checkbox"
                              className="hidden"
                              name={column}
                              id={column}
                              checked={emailColumnsSelected.includes(column)}
                              onChange={handleEmailSelect}
                            />
                            <label htmlFor={column} className="label--checkbox text--capitalize">
                              {column.replace('session_start', 'check-in_date').replace('_', ' ')}
                            </label>
                          </fieldset>
                        ))}

                        {/* Customer Account Emails */}
                        <fieldset>
                          <input
                            id="includeCustomerEmails"
                            type="checkbox"
                            className="hidden"
                            name="includeCustomerEmails"
                            checked={includeCustomerEmails}
                            onChange={handleIncludeCustomerEmailsChange}
                          />
                          <label htmlFor="includeCustomerEmails" className="label--checkbox text--capitalize">
                            Include Customer Emails{' '}
                            <Tooltip title="Checking this box will add all customer account emails that are subscribed to the gallery">
                              <i className="icon-info"></i>
                            </Tooltip>
                          </label>
                        </fieldset>
                      </div>
                    )}
                    {filterOptionsShow && filterOptions()}
                  </section>
                  <section className="flex-7 flex-12-sm modal__content-section">
                    <header className="modal__header">
                      <h2>Email Subjects</h2>
                      {!peopleRequesting && (
                        <p>
                          The following {`${recipients.length === 1 ? 'subject' : 'subjects'} (${recipients.length})`} will receive an email with their unique
                          flyer:
                        </p>
                      )}
                    </header>
                    <div className="job-marketing-flyers__email-recipients">
                      {fieldSelected?.value && peopleList.length === 0 ? (
                        <>
                          {subjectFilterRemoved || subjectAccessedFilter ? (
                            <p className="text--bold job-marketing-flyers__email-error animate">
                              There are no subjects matching the criteria you specified. Please update your selection.
                            </p>
                          ) : (
                            <figure className="flex middle nowrap job-marketing-flyers__email-recipient animate">
                              <div className="job-marketing-flyers__email-recipient-image-box">
                                <LazyLoadImage
                                  className="job-marketing-flyers__email-recipient-image"
                                  src={imagePeopleThumbnailPlaceholder}
                                  alt="loading"
                                  height={40}
                                  draggable="false"
                                />
                              </div>
                              <figcaption className="flex between middle">
                                <div className="flex column job-marketing-flyers__email-recipient-details">
                                  <p className="text--bold text--capitalize text--truncate text--nomargin">
                                    {fieldValues.length > 0 && emailColumnsSelected.length === 0 ? '...Please select email columns' : '...Loading Recipients'}
                                  </p>
                                </div>
                              </figcaption>
                            </figure>
                          )}
                        </>
                      ) : peopleRequesting ? (
                        <TableLoader rows={10} rowHeight={20} />
                      ) : (
                        <>{recipients.map((recipient) => recipientRowEmail({ recipient }))}</>
                      )}
                    </div>
                    <footer className="modal__footer modal__footer--fixed flex end gap-10">
                      <button className="button button--outline" type="button" name="cancel" onClick={onEmailToggle}>
                        Cancel
                      </button>
                      <button
                        className="button"
                        name="next"
                        type="button"
                        onClick={handleEmailNext}
                        data-loading={peopleRequesting}
                        disabled={peopleRequesting || !recipients.length}
                      >
                        Next
                      </button>
                    </footer>
                  </section>
                </main>

                {/* Show Email Screen send to people */}
                {showNext && (
                  <aside className="modal__box--overlay animate">
                    <header className="modal__header modal__header--flex">
                      <button className="button button--clean button--noborder button--small" name="back" type="button" onClick={handleEmailNext}>
                        <i className="icon-back"></i>
                      </button>
                      <h2 className="text--nomargin">Compose Email</h2>
                    </header>
                    <section>
                      <input
                        className="input--block"
                        type="text"
                        name="subject"
                        value={email.subject}
                        placeholder="Subject"
                        onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                        maxLength="50"
                        required
                      />
                      <p className="text--small">
                        <i>Ex. Important Information Regarding Picture Day</i>
                      </p>
                      <figure className="flex column middle center job-marketing-flyers__email-body-preview">
                        <img className="" src={imageDesignBasicFull} alt="Basic" height="100%" />
                        <figcaption>
                          <p>
                            PhotoDay will send each subject selected in the previous screen their own flyer containing a unique code to access their photos.
                          </p>
                        </figcaption>
                      </figure>
                    </section>
                    <footer className="modal__footer modal__footer--fixed">
                      {getSendButtonSection({ buttonDisabled: peopleRequesting || !email.subject })}
                    </footer>
                  </aside>
                )}
              </div>
            </aside>
          )}
        </>
      ) : (
        <>
          {type === 'organization' && (
            <aside className="modal animate">
              <div className="modal__box modal__box--large">
                <header className="modal__header modal__header--flex">
                  <button className="button button--clean button--noborder button--small" name="back" type="button" onClick={onEmailToggle}>
                    <i className="icon-back"></i>
                  </button>
                  <h2 className="text--nomargin">Email Organization</h2>
                </header>
                <main className="modal__content">
                  <CreatableSelect
                    isMulti
                    isClearable
                    name="emails"
                    autoFocus={true}
                    className="select mb-10"
                    classNamePrefix="select"
                    placeholder={'Email Addresses'}
                    value={email.emails.map((email) => ({ label: email, value: email }))}
                    onChange={(select) =>
                      setEmail({
                        ...email,
                        emails:
                          select && select.length > 0 ? select.filter((email) => email.value && email.value.includes('@')).map((email) => email.value) : []
                      })
                    }
                    options={organization.contacts?.map((contact) => ({ value: contact.email, label: contact.email }))}
                  />
                  <input
                    className="input--block mb-10"
                    type="text"
                    name="subject"
                    value={email.subject}
                    placeholder="Subject"
                    onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                    maxLength="50"
                    required
                  />
                  <textarea
                    className="textarea--block"
                    name="message"
                    value={email.message}
                    onChange={({ target }) => setEmail({ ...email, message: target.value })}
                    rows="20"
                    maxLength="1400"
                  />
                </main>
                <footer className="modal__footer">
                  {getSendButtonSection({ buttonDisabled: peopleRequesting || !(email.emails && email.emails.length && email.subject) })}
                </footer>
              </div>
            </aside>
          )}
          {type === 'people' && (
            <aside className="modal animate">
              <div className="modal__box modal__box--large">
                <header className="modal__header modal__header--flex">
                  <button className="button button--clean button--noborder button--small" name="back" type="button" onClick={onEmailToggle}>
                    <i className="icon-back"></i>
                  </button>
                  <h2 className="text--nomargin">Add Emails</h2>
                </header>
                <main className="modal__content text--left">
                  <fieldset>
                    <input
                      type="email"
                      name="email"
                      value={inputEmail}
                      placeholder="Add Email Address"
                      onChange={({ target }) => setInputEmail(target.value)}
                      onKeyDown={handleEmailAdd}
                      maxLength="50"
                    />
                    <p className="text--small">To add people, type an email address in and hit “Enter”.</p>
                  </fieldset>
                  <fieldset className="mb-40">
                    <input
                      id="includeCustomerEmails"
                      className="hidden"
                      type="checkbox"
                      checked={includeCustomerEmails}
                      onChange={handleIncludeCustomerEmailsChange}
                    />
                    <label htmlFor="includeCustomerEmails" className="label--checkbox">
                      Include Customer Emails{' '}
                      <Tooltip title="Checking this box will add all customer account emails that are subscribed to the gallery">
                        <i className="icon-info"></i>
                      </Tooltip>
                    </label>
                  </fieldset>
                  <Dropzone
                    multiple={false}
                    maxSize={10000000}
                    accept={{ 'text/csv': ['.csv'] }}
                    noKeyboard={true}
                    noDragEventsBubbling={true}
                    preventDropOnDocument={true}
                    onDropAccepted={handleDropAccepted}
                    noClick={true}
                  >
                    {({ getRootProps, getInputProps, open }) => (
                      <aside className="job-marketing-flyers__email-container" {...getRootProps()}>
                        <p>The following subjects ({email.emails.length}) will receive a flyer:</p>
                        <div className="flex column between panel panel--nomargin">
                          <ul className="job-marketing-flyers__email-list">
                            {email.emails
                              .sort((a, b) => a.localeCompare(b))
                              .map((email) => (
                                <li className="flex between nowrap middle" key={email}>
                                  <p className="text--truncate text--nomargin">{email}</p>
                                  <button className="button button--clean button-noborder" type="button" onClick={() => handleEmailRemove(email)}>
                                    <i className="icon-trash"></i>
                                  </button>
                                </li>
                              ))}
                          </ul>
                          <input {...getInputProps({ disabled: false })} />

                          <figure className="panel panel--dashed flex nowrap middle">
                            <img src={imageDragCvs} alt="Drag CSV file" width="44" />
                            <figcaption>
                              <p className="text--nomargin text--small">
                                Drag and drop CSV file containing email addresses or{' '}
                                <button className="button button--link" type="button" onClick={open}>
                                  upload the file here
                                </button>
                                .
                              </p>
                            </figcaption>
                          </figure>
                        </div>
                      </aside>
                    )}
                  </Dropzone>
                </main>
                <footer className="modal__footer end">
                  <button
                    className="button button--medium"
                    name="next"
                    type="button"
                    onClick={handleEmailNext}
                    data-loading={peopleRequesting}
                    disabled={peopleRequesting || !(email.emails && email.emails.length)}
                  >
                    Next
                  </button>
                </footer>

                {/* Show email screen send to people */}
                {showNext && (
                  <aside className="modal__box--overlay animate">
                    <header className="modal__header modal__header--flex">
                      <button className="button button--clean button--noborder button--small" name="back" type="button" onClick={handleEmailNext}>
                        <i className="icon-back"></i>
                      </button>
                      <h2 className="text--nomargin">Email</h2>
                    </header>
                    <section>
                      <input
                        className="input--block"
                        type="text"
                        name="subject"
                        value={email.subject}
                        placeholder="Subject"
                        onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                        maxLength="50"
                        required
                      />
                      <p className="text--small">
                        <i>Ex. Important Information Regarding Picture Day</i>
                      </p>
                      <figure className="flex column middle center job-marketing-flyers__email-body-preview--small">
                        <img src={imageDesignBasicFull} alt="Basic" height="100%" />
                        <figcaption>
                          <p>PhotoDay will send each email address added in the previous screen a flyer containing a code to access the gallery.</p>
                        </figcaption>
                      </figure>
                    </section>
                    <footer className="modal__footer modal__footer--fixed">
                      {getSendButtonSection({ buttonDisabled: peopleRequesting || !email.subject })}
                    </footer>
                  </aside>
                )}
              </div>
            </aside>
          )}
        </>
      )}
    </div>
  );
});

Email.propTypes = {
  type: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired,
  flyer: PropTypes.object.isRequired
};

Email.defaultProps = {
  type: '',
  job: {},
  flyer: {}
};

export default Email;
