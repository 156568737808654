import { FC, useState, ChangeEvent, Fragment } from 'react';

// Plugins
import Select, { SingleValue, GroupBase } from 'react-select';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Types
import { OrderItem, OrderItemProduct, OrderItemProductNode, OrderReprintReason, SelectOptionType } from '@/types';

interface ReprintInfo {
  reason?: string;
  extraInfo?: string;
  allProductsSelected: boolean;
  reprintProductIds?: string[];
}

interface ReprintPartialRequestModalProps {
  orderItems: OrderItem[];
  reprintReasonOptions: SelectOptionType[];
  onSubmit: ({ reason, extraInfo, allProductsSelected, reprintProductIds }: ReprintInfo) => void;
  onCancel: () => void;
}

const PRODUCT_NODE_TYPE_ALLOWED = 'image';

const ReprintPartialRequestModal: FC<ReprintPartialRequestModalProps> = ({ orderItems, reprintReasonOptions: reprintReasons, onSubmit, onCancel }) => {
  const [reprintReasonOptionSelected, setReprintReasonOptionSelected] = useState<SingleValue<SelectOptionType>>();
  const [reprintExtraInfo, setReprintExtraInfo] = useState<string>();
  const [reprintProductsSelectedIds, setReprintProductsSelectedIds] = useState<string[]>();

  // Helpers
  const getReprintableOrders = (orderItems: OrderItem[]): OrderItem[] => {
    const reprintableOrders: OrderItem[] = [];

    orderItems.forEach((orderItem: OrderItem) => {
      if (
        // NOT ONLY digital downloads
        orderItem.price_sheet_item?.digital_bundle === false &&
        // Check if all order item products are printable
        getReprintableProducts(orderItem).length > 0
      ) {
        reprintableOrders.push(orderItem);
      }
    });

    return reprintableOrders;
  };

  const getReprintableProducts = (orderItem: OrderItem): OrderItemProduct[] => {
    const reprintableProducts: OrderItemProduct[] = [];

    // Only products that are NOT digital downloads
    orderItem.order_item_products.forEach((itemProduct: OrderItemProduct) => {
      if (itemProduct.digital_download === false) {
        reprintableProducts.push(itemProduct);
      }
    });

    return reprintableProducts;
  };

  const getAllReprintableProducts = (orderItems: OrderItem[]): OrderItemProduct[] => {
    const allReprintableProducts: OrderItemProduct[] = [];

    orderItems.forEach((orderItem: OrderItem) => {
      allReprintableProducts.push(...getReprintableProducts(orderItem));
    });

    return allReprintableProducts;
  };

  // UI Handlers
  const handleReasonChange = (reasonSelected: SingleValue<SelectOptionType>): void => setReprintReasonOptionSelected(reasonSelected);
  const handleExtraInfoChange = ({ target }: ChangeEvent<HTMLTextAreaElement>): void => setReprintExtraInfo(target.value);

  const handleRequestProductSelectionAll = (allChecked: boolean): void => {
    if (allChecked) {
      const allReprintableProductIds = getAllReprintableProducts(orderItems).map((product) => product.id);
      setReprintProductsSelectedIds(allReprintableProductIds);
    } else {
      setReprintProductsSelectedIds(undefined);
    }
  };
  const handleRequestProductSelection = ({ itemChecked, selectedProductId }: { itemChecked: boolean; selectedProductId: string }): void => {
    setReprintProductsSelectedIds((prevState) =>
      itemChecked ? [...(prevState ?? []), selectedProductId] : prevState?.filter((id) => id !== selectedProductId)
    );
  };

  const allProductsSelected = reprintProductsSelectedIds?.length === getAllReprintableProducts(orderItems).length;

  const handleSubmit = (): void => {
    onSubmit({
      reason: reprintReasonOptionSelected?.value,
      extraInfo: reprintExtraInfo,
      allProductsSelected,
      reprintProductIds: reprintProductsSelectedIds
    });
  };
  const handleCancel = (): void => onCancel();

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--nopadding">
        <main className="modal__content modal__box--large flex">
          <aside className="flex-4 p-20 bg-gray">
            <h2>Request a Reprint</h2>
            <form>
              <fieldset className="mb-30">
                <label htmlFor="reason">Please select the reason for your request</label>
                <Select
                  id="reason"
                  className="select"
                  classNamePrefix="select"
                  name="reason"
                  value={reprintReasonOptionSelected}
                  options={reprintReasons as GroupBase<string>[]}
                  onChange={handleReasonChange}
                />
              </fieldset>
              {reprintReasonOptionSelected?.value === OrderReprintReason.IncorrectImage ? (
                <aside className="panel panel--error animate">
                  <p className="text--small">
                    Please note we cannot accommodate an automatic reprint that requires corrections to an image, crop, or customization. For these requests,
                    please reach out to our support team via the Bubble or at <a href="mailto:support@photoday.io">support@photoday.io</a>.
                  </p>
                </aside>
              ) : (
                <fieldset className="animate">
                  <label htmlFor="extraInfo">Enter any additional information</label>
                  <textarea id="extraInfo" name="extraInfo" value={reprintExtraInfo ?? ''} rows={6} maxLength={244} onChange={handleExtraInfoChange} />
                </fieldset>
              )}
            </form>
          </aside>
          <section className="flex-8 p-20">
            <h5>Select Product(s) for Reprint</h5>
            <article className="table-box table-box--scrollable mb-20">
              <table className="table">
                <thead className="table__header">
                  <tr>
                    <th>
                      <input
                        id="all"
                        className="hidden"
                        type="checkbox"
                        name="all"
                        checked={allProductsSelected}
                        onChange={({ target }) => handleRequestProductSelectionAll(!!target.checked)}
                      />
                      <label htmlFor="all" className="label--checkbox label--clean" />
                    </th>
                    <th>Product</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {getReprintableOrders(orderItems).map((orderItem: OrderItem) => (
                    <Fragment key={orderItem.id}>
                      <tr>
                        <td data-header="Product" colSpan={3}>
                          <h4 className="text--nomargin">
                            {orderItem.name} <small className="text--normal">(Quantity of {orderItem.quantity})</small>
                          </h4>
                          <small className="text--nomargin">{orderItem.description}</small>
                        </td>
                      </tr>
                      {getReprintableProducts(orderItem).map((itemProduct: OrderItemProduct) => (
                        <tr key={`${orderItem.id}${itemProduct.id}`}>
                          <td data-header="Checkbox">
                            <input
                              id={itemProduct.id}
                              className="hidden"
                              type="checkbox"
                              name={itemProduct.id}
                              checked={!!reprintProductsSelectedIds?.includes(itemProduct.id)}
                              onChange={({ target }) => handleRequestProductSelection({ selectedProductId: itemProduct.id, itemChecked: !!target.checked })}
                            />
                            <label htmlFor={itemProduct.id} className="label--checkbox label--clean" />
                          </td>
                          <td data-header="Product">
                            <figure className="flex middle nowrap gap-20">
                              <img src={imageScaling({ url: itemProduct.product?.image_url, size: 'xsmall' })} alt={itemProduct.product?.name} />
                              <figcaption>
                                <span className="text--block text--small">{itemProduct.product?.name}</span>
                                {itemProduct.product?.bulk_multiplier > 1 && (
                                  <small className="text--xsmall text--bold">Bulk multiplier {itemProduct.product?.bulk_multiplier}</small>
                                )}
                              </figcaption>
                            </figure>
                          </td>
                          <td data-header="Image">
                            <ul className="flex gap-10">
                              {itemProduct.order_item_product_nodes
                                ?.filter((productNode: OrderItemProductNode) => productNode.layout_node_type === PRODUCT_NODE_TYPE_ALLOWED)
                                .map((productNode: OrderItemProductNode) => (
                                  <li key={`${itemProduct.id}${productNode.id}`}>
                                    <figure className="flex middle nowrap gap-20">
                                      <img src={imageScaling({ url: productNode.photo?.image_url, size: 'xsmall' })} alt={productNode.photo?.image_filename} />
                                      <figcaption>
                                        <span className="text--block text--small">{productNode.photo?.image_filename}</span>
                                        <small className="text--xsmall text--bold">{productNode.description}</small>
                                        {productNode.photo?.retouched_image_url && <small className="text--xsmall text--bold">Basic Retouching Added</small>}
                                      </figcaption>
                                    </figure>
                                  </li>
                                ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </article>
            <footer className="flex end gap-10">
              <button className="button button--blue-outline" type="button" name="cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="button"
                type="button"
                name="submit"
                disabled={!reprintReasonOptionSelected || !reprintExtraInfo || reprintReasonOptionSelected?.value === OrderReprintReason.IncorrectImage}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </footer>
          </section>
        </main>
      </div>
    </aside>
  );
};

export default ReprintPartialRequestModal;
